export const environment = {
  production: false,
  api: {
    url: 'https://api-dev.angell.bike',
  },
  tenant: {
    name: 'ANGELL',
    label: 'Angell Mobility',
    logo: 'assets/ANGELL/logo.svg',
    favico: 'assets/ANGELL/favicon.ico'
  },
  appstorelink: 'https://apps.apple.com/fr/app/angell/id1526657209',
  playstorelink: 'https://play.google.com/store/apps/details?id=com.angell.bike&hl=fr&gl=US'
};
